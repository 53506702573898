<template>
  <div>
    <tableComponent ref="tableComponent" :tableSettings="tableSettings" :tableData="tableData" :headers="headers"
      @newDocument="openMenu" @exportWithTimespan="exportWithTimespan" />
    <v-dialog v-model="isMenuOpen" max-width="800px">
      <v-card>
        <v-list>
          <!-- Erster Button -->
          <v-list-item>
            <v-btn block color="primary" @click="newDocumentShort">
              Unfallmeldung nicht meldepflichtig (weniger als 3 Tage Ausfallzeit)
            </v-btn>
          </v-list-item>

          <!-- Zweiter Button -->
          <v-list-item>
            <v-btn block color="primary" @click="newDocumentLong">
              Unfallmeldung meldepflichtig (mehr als 3 Tage Ausfallzeit)
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <incidentReportExportForm ref="incidentReportExportForm" :data="incidentReportExportForm"
      @reloadData="reloadDataFunc" />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";
import incidentReportExportForm from "./incidentReportExportForm.vue";

export default {
  components: {
    tableComponent,
    incidentReportExportForm,
  },
  data: () => ({
    isMenuOpen: false,
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "incidentReports.createExport",
          color: "grey",
          action: "exportWithTimespan",
          icon: "download",
          iconDark: true,
          dark: true,
          outlined: true,
        },
        {
          title: "incidentReports.createIncidentReports",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedIncidentReportName",
          componentName: "formattedIncidentReportName",
        },
        {
          id: getUniqueId(),
          slotName: "item.formattedIncidentReportDate",
          componentName: "formattedIncidentReportDate",
        },
        {
          id: getUniqueId(),
          slotName: "item.formattedIncidentReportBackToWork",
          componentName: "formattedIncidentReportBackToWork",
        },
        {
          id: getUniqueId(),
          slotName: "item.formattedIncidentReportCommutingIncident",
          componentName: "formattedIncidentReportCommutingIncident",
        },
        {
          id: getUniqueId(),
          slotName: "item.incidentReportFormattedUnableToWorkDays",
          componentName: "incidentReportFormattedUnableToWorkDays",
        },
        {
          id: getUniqueId(),
          slotName: "item.incidentReportFormattedCreatedAt",
          componentName: "incidentReportFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.incidentReportAction",
          componentName: "incidentReportAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "incidentReport",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "incidentReportForm",
    },
    incidentReportExportForm: {
      open: false,
      title: "Unfallmeldungen exportieren",
      item: null,
      method: "export",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("incidentReports.tableHeader.name"),
          value: "formattedIncidentReportName",
          align: "left",
        },
        {
          text: this.$t("incidentReports.tableHeader.incidentDate"),
          value: "formattedIncidentReportDate",
        },
        {
          text: this.$t("incidentReports.tableHeader.backToWork"),
          value: "formattedIncidentReportBackToWork",
        },
        {
          text: this.$t("incidentReports.tableHeader.wayIncident"),
          value: "formattedIncidentReportCommutingIncident",
        },
        {
          text: this.$t("incidentReports.tableHeader.absenceInDays"),
          value: "incidentReportFormattedUnableToWorkDays",
        },
        {
          text: this.$t("incidentReports.tableHeader.createdAt"),
          value: "incidentReportFormattedCreatedAt",
        },
        {
          text: "",
          value: "incidentReportAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    openMenu() {
      this.isMenuOpen = true
    },
    newDocumentShort() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    newDocumentLong() {
      this.$router.push({
        name: "incidentReportFormLong",
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    exportWithTimespan() {
      this.incidentReportExportForm.open = true;
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store.dispatch("deleteMultipleIncidentReport", item).then(() => {
        this.reloadDataFunc();
        this.multipleDeleteDialog.open = false;
        this.multipleDeleteDialog.items = [];
      });
    },
  },
};
</script>
